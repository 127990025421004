var site = site || {};
var generic = generic || {};
var prodcat = prodcat || {};

site.endeca.results.bestsellers = jQuery.extend(true, {}, generic.endeca.results.bestsellers, {

  displayResultNodes: function(args) {
    this._super(args);

    var $productGrid = $('.js-product-grid', this.parentNode);
    if (this.resultData.length > 0) {
      prodcat.data.updateProducts(this.resultData, false);

      if (prodcat.ui.quickshop) {
        prodcat.ui.quickshop.reset($productGrid);
        prodcat.ui.quickshop.init($productGrid);
      }

      Drupal.attachBehaviors(this.parentNode, Drupal.settings);
    }
  }
});
